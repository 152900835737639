import { StatsigUser } from "statsig-node"
import Cookies from "universal-cookie"

import { isBrowser } from "../../utils/browser"
import { generateId } from "../../utils/random-id-generator"
import { getUserEmail } from "../../utils/user-data"
import { EXPERIMENT_UID_COOKIE } from "../constants"
import { parseCookies } from "./utils"

const CAMPAIGN_KEY = "utm_campaign" as const

type StatsigUserCustomData = {
  utm_campaign?: string
  clientOrEdge: "client" | "edge"
}
type StatsigCustomUser = Omit<
  StatsigUser,
  "userID" | "custom" | "customIDs"
> & {
  custom?: StatsigUserCustomData
  userID: string
  privateAttributes?: undefined
  customIDs: {
    statsigUserId: string
  }
}
type CookieGetter = (cookieName: string) => string | undefined

const getCustomDataFromRequest = (req: Request): StatsigUserCustomData => {
  const { url } = req
  const cookies = parseCookies(req)
  const { searchParams } = new URL(url)
  const utmCampaign = searchParams.get(CAMPAIGN_KEY) ?? cookies[CAMPAIGN_KEY]

  return {
    [CAMPAIGN_KEY]: utmCampaign,
    clientOrEdge: "edge",
  }
}

const getCustomDataFromBrowser = (): StatsigUserCustomData => {
  if (!isBrowser()) {
    throw new Error("Tried to get browser user data on non-browser environment")
  }
  const { searchParams } = new URL(window.location.href)
  const cookies = new Cookies()
  const utmCampaing =
    searchParams.get(CAMPAIGN_KEY) ?? cookies.get(CAMPAIGN_KEY)

  return {
    [CAMPAIGN_KEY]: utmCampaing,
    clientOrEdge: "client",
  }
}

export function getStatsigUser(getCookie: CookieGetter): StatsigCustomUser
export function getStatsigUser(
  getCookie: CookieGetter,
  req: Request,
): StatsigCustomUser

export function getStatsigUser(
  getCookie: CookieGetter,
  req?: Request,
): StatsigCustomUser {
  // Get the user ID from the cookie or get a new one
  const cookieUserId = getCookie(EXPERIMENT_UID_COOKIE)
  const userID = cookieUserId ?? generateId()
  const shared = {
    userID,
    customIDs: {
      statsigUserId: userID,
    },
  }

  if (req) {
    const { headers } = req

    const getHeader = (headerName: string) =>
      headers.get(headerName) ?? undefined

    return {
      ...shared,
      ip: getHeader("cf-connecting-ip"),
      userAgent: getHeader("user-agent"),
      country: getHeader("cf-ipcountry"),
      locale: getHeader("accept-language"),
      custom: getCustomDataFromRequest(req),
    }
  }

  if (!isBrowser()) {
    return shared
  }

  const locale = window.navigator.language
  const country = locale.split("-")[1]
  return {
    ...shared,
    email: getUserEmail(),
    userAgent: window.navigator.userAgent,
    country,
    locale,
    custom: getCustomDataFromBrowser(),
    // statsigEnvironment: window.statsig?.user?.statsigEnvironment ?? {},
  }
}
