import React from "react"

import { HtmlHeadingTagType } from "../title"
import * as styles from "./index.module.scss"

interface Props extends React.HTMLAttributes<HTMLHeadingElement> {
  variant: "1" | "2" | "3" | "4" | "5"
  as: HtmlHeadingTagType
  textColor?: "white" | "none"
}

function Header({
  as,
  variant,
  className,
  textColor = "none",
  ...rest
}: Props) {
  const Element = as
  const variantClassName = styles[`variant${variant}`]

  return (
    <Element
      className={`${styles.header} ${variantClassName} ${
        textColor === "white" ? styles.headerWhite : ""
      } ${className}`}
      {...rest}
    />
  )
}

export default Header
