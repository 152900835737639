import * as React from "react"

import * as styles from "./index.module.scss"

type Props = {
  color?: "light" | "dark" | "primaryPressed"
  size?: "medium" | "large" | "xlarge"
}

function Loading({ color = "dark", size = "large" }: Props) {
  return (
    <div
      className={`${styles.container} ${styles[color]} ${styles[size]}`}
      aria-busy="true"
      data-chromatic="ignore"
    >
      <div className={styles.border1} />
      <div className={styles.border2} />
      <div className={styles.border3} />
      <div className={styles.border4} />
    </div>
  )
}

export default Loading
