import Cookies from "universal-cookie"

import {
  getExperimentBucketCookieKey,
  MIDDLEWARE_EXPERIMENT_IDS_COOKIE,
} from "../constants"
import { generateCookieString } from "../middleware/utils"
import { BucketUncheckedCombination } from "../types"
import { Pathname } from "../types/base-paths"
import { getExperimentsByPathname } from "../utils"

export const MIDDLEWARE_EXPERIMENT_IDS_SEPARATOR = "," // This cannot be ";", otherwise, browser's cookie logic will handle it wrongly.

export const getMiddlewareExperimentIds = (isMiddleware: boolean): string[] => {
  if (isMiddleware) {
    return []
  }
  const cookies = new Cookies()
  const experimentIdsStringFromCookie: string =
    cookies.get(MIDDLEWARE_EXPERIMENT_IDS_COOKIE) ?? ""
  const splitExperimentIds = experimentIdsStringFromCookie?.split(
    MIDDLEWARE_EXPERIMENT_IDS_SEPARATOR,
  )
  return splitExperimentIds
}
export const experimentIdCookieExists = (
  experimentId: string,
  isMiddleware: boolean,
) => {
  if (isMiddleware) {
    return false
  }
  const cookies = new Cookies()
  return cookies.get(getExperimentBucketCookieKey(experimentId)) !== undefined
}

export const getNonBucketedExperiments = (
  pathname: Pathname,
  isMiddleware: boolean,
) => {
  const matchingExperiments = getExperimentsByPathname(pathname)
  const experimentIdsSetThroughMiddleware =
    getMiddlewareExperimentIds(isMiddleware)
  return matchingExperiments.filter(
    ({ id }) =>
      !experimentIdsSetThroughMiddleware.includes(id) ||
      !experimentIdCookieExists(id, isMiddleware),
  )
}

export const getMiddlewareExperimentCookie = (
  bucketCombination: BucketUncheckedCombination<Pathname>,
) => {
  const experimentIds = Object.keys(bucketCombination)
  const middlewareExperimentCookieValue = experimentIds.join(
    MIDDLEWARE_EXPERIMENT_IDS_SEPARATOR,
  )
  return generateCookieString(
    MIDDLEWARE_EXPERIMENT_IDS_COOKIE,
    middlewareExperimentCookieValue,
    { maxAge: "session" },
  )
}
