import React from "react"

import * as styles from "./index.module.scss"

interface Props {
  id?: string
  className?: string
  children?: React.ReactNode
  collapsibleContent?: React.ReactNode
}

function Paper({ className, ...otherProps }: Props) {
  return <div className={`${styles.paper} ${className}`} {...otherProps} />
}

export default Paper
