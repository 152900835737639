// extracted by mini-css-extract-plugin
export var button = "P_l";
export var children = "P_b9";
export var disabled = "P_b2";
export var fullWidth = "P_b8";
export var iconWrapper = "P_cc";
export var large = "P_b5";
export var link = "P_bK";
export var loading = "P_b1";
export var loadingContainer = "P_cb";
export var medium = "P_b6";
export var primary = "P_b0";
export var secondary = "P_b3";
export var small = "P_b7";
export var tertiary = "P_b4";