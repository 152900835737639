import { navigate } from "gatsby"
import React from "react"

import { Pathname } from "../../../experimentation/types/base-paths"
import { isBrowser } from "../../../utils/browser"
import useClickOutside from "../../../utils/hooks/use-click-outside"
import useDisableBodyScroll from "../../../utils/hooks/use-disable-body-scroll"
import ExperimentStatistics, {
  ExperimentStatisticsProps,
} from "../experiment-statistics"
import ExperimentStatisticsMini from "../experiment-statistics-mini"
import * as styles from "./index.module.scss"

export const POPUP_HASH = "#experiment-popup"

function ExperimentStatisticsPopup<T extends Pathname>(
  props: ExperimentStatisticsProps<T>,
) {
  const [isClosed, setIsClosed] = React.useState(false)
  const containerRef = React.useRef<HTMLDivElement>(null)

  const isFullSizePopUp = isBrowser() && window.location.hash === POPUP_HASH
  useDisableBodyScroll(isFullSizePopUp)
  useClickOutside(containerRef, () => {
    navigate("#")
  })

  if (isClosed) {
    return null
  }

  if (isFullSizePopUp) {
    return (
      <div className={styles.fullSize} ref={containerRef}>
        <div className={styles.internal}>
          <ExperimentStatistics {...props} />
        </div>
      </div>
    )
  }

  return (
    <ExperimentStatisticsMini
      {...props}
      onClose={() => setIsClosed(true)}
      onClick={() => navigate(POPUP_HASH)}
    />
  )
}

export default ExperimentStatisticsPopup
