/* eslint-disable jsx-a11y/label-has-associated-control */
import * as React from "react"

import * as styles from "./index.module.scss"

type Props = React.LabelHTMLAttributes<HTMLLabelElement>

const Label: React.FC<Props> = ({ htmlFor, className, ...otherProps }) => {
  return (
    <label
      className={`${styles.label} ${className}`}
      htmlFor={htmlFor}
      {...otherProps}
    />
  )
}

export default Label
