const COOKIE_SEPARATOR = ";"
const DEFAULT_COOKIE_MAX_AGE = 7776000

export function htmlReplacements(
  cookieList: string[],
  replacementHtml: string,
  url: URL,
) {
  // This is a workaround, due to wide issues with setting multiple cookie headers, https://github.com/sveltejs/kit/issues/9221. The workaround described here doesn't work.
  // Instead of passing them in the header, we modify the HTML to include a script that sets the cookies.
  const cookieScript = `${cookieList
    .map(cookie => `document.cookie = "${cookie}"`)
    .join(COOKIE_SEPARATOR)}${COOKIE_SEPARATOR}`

  const htmlWithScripts = replacementHtml
    .replace("</body></html>", `<script>${cookieScript}</script></body></html>`)
    .replace(
      `</head>`,
      `<meta property="static-html-experiment-path" content="${url.pathname}" /></head>`, // Sets a meta tag that allows us to know where the HTML came from
    )
  return htmlWithScripts
}
export function parseCookies(request: {
  headers: { get: (cookieName: string) => string | null }
}) {
  const cookies: Record<string, string> = {}
  const cookieHeader = request.headers.get("cookie") ?? ""
  cookieHeader.split(COOKIE_SEPARATOR).forEach(cookie => {
    const [name, value] = cookie.split("=").map(c => c.trim())
    if (name && value) {
      const decodedValue = (() => {
        try {
          return decodeURIComponent(value)
        } catch (e) {
          console.error(e, value)
        }
      })()
      if (decodedValue) {
        cookies[name] = decodedValue
      }
    }
  })
  return cookies
}

export type CookieOptions = {
  maxAge?: number | "session"
  path?: string
}

export const generateCookieString = (
  name: string,
  value: string,
  options?: CookieOptions,
) => {
  return `${name}=${value}; ${
    options?.maxAge === "session"
      ? ""
      : `Max-Age=${options?.maxAge ?? DEFAULT_COOKIE_MAX_AGE}; `
  }Path=${options?.path ?? "/"}; SameSite=Lax`
}
