import React from "react"

import { classNames } from "../../utils/class-names"
import FeatherIcon from "../feather-icon"
import * as styles from "./index.module.scss"

interface Props {
  size?: "medium" | "small"
  isOpen?: boolean
}

const ChevronDropdown = ({ size = "medium", isOpen = false }: Props) => {
  return (
    <FeatherIcon
      type="chevron-down"
      className={classNames(
        styles.chevron,
        styles[size],
        isOpen && styles.chevronOpen,
      )}
    />
  )
}

export default ChevronDropdown
