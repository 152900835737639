import { useEffect } from "react"

const useDisableBodyScroll = (disable: boolean) => {
  useEffect(() => {
    if (disable) {
      document.documentElement.style.overflow = "hidden"
    }
    return () => {
      document.documentElement.style.overflow = ""
    }
  }, [disable])
}

export default useDisableBodyScroll
