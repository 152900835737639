import React, { ReactNode, useRef } from "react"

import useWindowSize from "../../utils/hooks/use-window-size"
import * as styles from "./index.module.scss"

interface Props {
  trigger: ReactNode
  content: ReactNode
  toggleCollapse: () => void
  isCollapsed: boolean
  padding?: string
  className?: string
  triggerPosition?: "top" | "bottom"
}

function Accordion({
  trigger,
  content,
  toggleCollapse,
  isCollapsed,
  padding,
  className,
  triggerPosition = "top",
}: Props) {
  const heightRef = useRef<HTMLDivElement>(null)
  useWindowSize() // Called to force a re-render on resize, in order for clientHeight to be updated.
  const isTopTrigger = triggerPosition === "top"

  return (
    <button
      style={{ padding }}
      type="button"
      onClick={toggleCollapse}
      className={`${styles.button} ${className}`}
      data-testid="AccordionButton"
    >
      {isTopTrigger && trigger}

      <div
        className={styles.contentContainer}
        data-testid="AccordionContent"
        style={{
          maxHeight: isCollapsed ? 0 : heightRef.current?.clientHeight,
        }}
      >
        <div ref={heightRef}>{content}</div>
      </div>
      {!isTopTrigger && trigger}
    </button>
  )
}

export default Accordion
