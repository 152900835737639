import * as React from "react"

import * as styles from "./index.module.scss"

interface Props extends React.HTMLAttributes<HTMLParagraphElement> {
  variant: "1" | "2" | "3"
  as?: "p" | "span"
}

/** @deprecated */
const ParagraphDeprecated: React.FC<Props> = ({
  variant,
  as = "p",
  className,
  ...props
}) => {
  const Element = as
  return (
    <Element
      className={`${styles.paragraph} ${styles[`v${variant}`]} ${className}`}
      {...props}
    />
  )
}

export default ParagraphDeprecated
