import { isString } from "../../utils/types"

export type EmptyStringTuple = ["", "", ""]
type PathnameParts = {
  [K in Extract<keyof EmptyStringTuple, `${number}`>]: `/${string}`
}
type JoinPathParts<T extends Record<string, string>> = keyof T extends never
  ? ""
  :
      | {
          [K in keyof T]: `${T[K]}${JoinPathParts<Omit<T, K>>}`
        }[keyof T]
      | {
          [K in keyof T]: `${JoinPathParts<Omit<T, K>>}`
        }[keyof T]
export type Pathname = Exclude<JoinPathParts<PathnameParts>, "">

export const isPathname = (str: unknown): str is Pathname =>
  isString(str) && str.startsWith("/")
