import { useState } from "react"

import { isBrowser } from "../../browser"
import { Viewport } from "../../constants"
import useEventListener from "../use-event-listener"
import useIsomorphicLayoutEffect from "../use-isomorphic-layout-effect"

interface WindowSize {
  width: number
  height: number
}

const SERVER_SIDE_WINDOW_WIDTH = Viewport.large

function useWindowSize(): WindowSize {
  const browser = isBrowser()

  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: SERVER_SIDE_WINDOW_WIDTH,
    height: SERVER_SIDE_WINDOW_WIDTH,
  })

  const setSizeFromWindow = () => {
    if (browser) {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
  }

  useEventListener("resize", setSizeFromWindow)

  useIsomorphicLayoutEffect(() => {
    setSizeFromWindow()
  }, [])

  return windowSize
}

export default useWindowSize
