import { isBrowser } from "../browser"

export enum LeadRoutes {
  commercial = "commercial",
  webinar = "webinar",
  enterprise = "enterprise",
  sdr = "sdr",
}

export const isInternalRoute = () =>
  isBrowser() && window.location.pathname.startsWith("/internal/")

export const getDemoId = () => {
  if (!isBrowser()) return ""
  const params = new URLSearchParams(window.location.search)
  return params.get("id") || ""
}

export const setCampaignId = () => {
  if (!isBrowser()) return
  const params = new URLSearchParams(window.location.search)
  const utmCampaignValue = params.get("utm_campaign")
  if (utmCampaignValue) {
    localStorage.setItem("utmCampaign", utmCampaignValue)
  }
}

interface PathSettings {
  bookDemoText?: string
  leadLevel?: LeadRoutes.sdr
}
export const getSettingsByPath = (pathname: string): PathSettings => {
  if (pathname.startsWith("/p/")) {
    // lower funnel
    return {
      bookDemoText: "Book a demo",
    }
  }
  if (pathname.startsWith("/pt/")) {
    // upper funnel
    return {
      bookDemoText: "Book a call",
      leadLevel: LeadRoutes.sdr,
    }
  }
  return {}
}
