export type DividerOption = {
  divider: true
}
export type ValueOption = {
  readonly value: string
  readonly label: string
}

export type Option = ValueOption | DividerOption

export const isDivider = (option: Option): option is DividerOption =>
  "divider" in option
export const isValueOption = (option: Option): option is ValueOption =>
  !("divider" in option)
