import { isDivider, Option } from "./types"

export function getOptionsWithMergedDividers(options: readonly Option[]) {
  const mergedOptions = options.reduce<Option[]>((acc, option) => {
    const lastAcc = acc[acc.length - 1]
    const isFirst = !acc.length
    if (!isDivider(option) || (!isFirst && !isDivider(lastAcc))) {
      acc.push(option)
    }
    return acc
  }, [])
  const len = mergedOptions.length
  if (len && isDivider(mergedOptions[len - 1])) {
    mergedOptions.pop()
  }
  return mergedOptions
}
