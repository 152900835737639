// extracted by mini-css-extract-plugin
export var centeredTitle = "c1_jg";
export var childrenContainer = "c1_jj";
export var container = "c1_h";
export var dark = "c1_dk";
export var innerContainer = "c1_cr";
export var left = "c1_cz";
export var light = "c1_dj";
export var noBottomPadding = "c1_jb";
export var noRectangle = "c1_jf";
export var noTopPadding = "c1_jc";
export var overflowHidden = "c1_jd";
export var right = "c1_cB";
export var subtitle = "c1_s";
export var tan = "c1_cw";
export var textContainer = "c1_bV";
export var textContainerCustomWidth = "c1_jh";