import { DEFAULT_BUCKET } from "../../experimentation/constants"

const bucketReference = {
  [DEFAULT_BUCKET]:
    "This is the default bucket, in production it represents the control group, which is the already existing behavior. Whenever the bucket is not a valid bucket for the experiment, it falls back to this",
  test: "This is the test bucket, in production it represents the new behavior we want to test. This should only show up if the user is actually bucketed to the test bucket, otherwise the default (control) should be shown",
  "layer assignment":
    "This is the reserve bucket, in production it represents the reserve bucket, i.e., users who are not assigned to any bucket at the moment, but might in the future",
} as const
export const getBucketReference = (bucket: string) => {
  return (
    bucketReference[bucket as keyof typeof bucketReference] ??
    "Bucket not on the list, please inform engineering that this should be added as a possibility"
  )
}
export const BUCKET_BACKGROUND_COLORS = {
  control: "green",
  test: "red",
  "Bucket not on the list": "gray",
}

export const IS_DYNAMIC_BACKGROUND_COLORS = {
  "Page is statically generated": "orange",
  "Client side JS is rendering the page": "blue",
}
