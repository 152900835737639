/**
 * Returns a new array containing only the unique elements from the original array,
 * based on the values returned by the mapper function.
 * If no mapper function is provided, the array elements are used as keys.
 *
 * @template T - The type of elements in the array.
 * @template U - The type of mapped elements.
 * @param {T[]} arr - The array to process.
 * @param {(item: T) => U} mapper - The function used to convert the array elements.
 * @returns {T[]} A new array containing only the unique elements from the original array, based on the values returned by the mapper function.
 *
 * @example
 * ```ts
 * uniqBy([1.2, 1.5, 2.1, 3.2, 5.7, 5.3, 7.19], Math.floor);
 * // [1.2, 2.1, 3.2, 5.7, 7.19]
 * ```
 */

export function uniqBy<T extends string | number>(arr: readonly T[]): T[]
export function uniqBy<T, U>(arr: readonly T[], mapper: (item: T) => U): T[]
export function uniqBy<T, U>(arr: readonly T[], mapper?: (item: T) => U): T[] {
  const map = new Map<T | U, T>()

  for (const item of arr) {
    const key = mapper ? mapper(item) : item

    if (!map.has(key)) {
      map.set(key, item)
    }
  }

  return Array.from(map.values())
}
