import { experimentMatcherData } from "./statsig-experiment-data"
import { ExperimentMatchers } from "./types"
import { Pathname } from "./types/base-paths"

export const EXPERIMENT_ID_VARIANT_SEPARATOR = "="
export const EXPERIMENT_PAIR_SEPARATOR = ";"
export const MOCK_EXPERIMENT_PATH = "/mock-experiment"
export const EXPERIMENT_PATH_PREFIX = "/experiment"
export const EXPERIMENT_UID_COOKIE = "experiment_uid"
export const DEFAULT_BUCKET = "control"

/** This cookie stores what experiments were set on the middleware so that statsig doesn't reset it on the client-side.
 * This is needed because the Statsig settings stored on the middleware don't get updated immediately after a settings
 * change on the Statsig platform. Between the change and the middleware update there may be a mismatch between middleware
 * and client-side bucketing.
 */
export const MIDDLEWARE_EXPERIMENT_IDS_COOKIE =
  "experiment_ids_middleware_page_redirected"
export const GLOBAL_EXPERIMENT_MATCHER = "/**"

const EXPERIMENT_ID_COOKIE_PREFIX = "experiment_bucket_"
export const getExperimentBucketCookieKey = (experimentId: string) =>
  EXPERIMENT_ID_COOKIE_PREFIX + experimentId

export const filterMockExperiments = (paths: Pathname[]) =>
  paths.filter(path => {
    const isProduction = process.env.GATSBY_ENV === "production"
    const isMockExperimentPath = path.includes(MOCK_EXPERIMENT_PATH)
    return !isProduction || !isMockExperimentPath
  })

const UNFILTERED_EXPERIMENT_MATCHERS = Object.keys(
  experimentMatcherData,
) as Array<ExperimentMatchers>
export const EXPERIMENT_MATCHERS = filterMockExperiments(
  UNFILTERED_EXPERIMENT_MATCHERS,
)
