import { isDataDogEnvironment } from "../../utils/browser"
import { isString } from "../../utils/types"
import { DEFAULT_BUCKET } from "../constants"
import type { ExperimentGetter, StatsigUserInfo } from "../middleware/types"
import {
  BucketUncheckedCombination,
  Experiment,
  ExperimentsMatchedByPathname,
} from "../types"
import { Pathname } from "../types/base-paths"

const NO_BUCKET_SET = "NO_BUCKET_SET"
const DECISION_MADE_BUCKET_SUFFIX = " (launched)"

export const getBucketByExperiment = (
  experiment: Experiment,
  getExperiment: ExperimentGetter,
  userInfo: StatsigUserInfo,
) => {
  if (isDataDogEnvironment()) {
    return DEFAULT_BUCKET
  }
  const bucketOrError = (() => {
    try {
      const statsigExperiment = getExperiment(userInfo, experiment.id)
      const groupName = statsigExperiment
        .getGroupName()
        ?.toLowerCase()
        ?.replace(DECISION_MADE_BUCKET_SUFFIX, "")
      if (!groupName) {
        throw new Error(
          `Experiment Id: ${experiment.id} - NO_BUCKET_RETURNED, reason: ${
            statsigExperiment.getEvaluationDetails?.()?.reason ??
            "Error reason is not available when running on the edge"
          }`,
        )
      }
      return groupName
    } catch (error) {
      console.error(error)
      const message = error instanceof Error ? error.message : error
      if (isString(message)) {
        return `BUCKETING_ERROR: ${message}`
      }
    }
  })()

  return bucketOrError ?? NO_BUCKET_SET
}

type Args<T extends Pathname> = {
  experiments: ExperimentsMatchedByPathname<T>[]
  getExperiment: ExperimentGetter
  userInfo: StatsigUserInfo
}

export const getExperimentBuckets = <T extends Pathname>({
  experiments,
  getExperiment,
  userInfo,
}: Args<T>): BucketUncheckedCombination<T> | undefined => {
  if (!experiments.length) {
    return
  }

  return experiments.reduce<BucketUncheckedCombination<T>>(
    (acc, experiment) => {
      const bucketOrError = getBucketByExperiment(
        experiment,
        getExperiment,
        userInfo,
      )

      // @ts-expect-error Typescript can't detect that experiment.id is of type keyof BucketUncheckedCombination<T>
      acc[experiment.id] = bucketOrError

      return acc
    },
    {},
  )
}
