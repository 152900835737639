import React, { useEffect } from "react"

import { isBrowser } from "../../utils/browser"

export const SEGMENT_LOADED_EVENT_ID = "segmentLoaded" as const

export function useSegmentIsLoaded() {
  const [segmentLoadedEventFired, setSegmentIsLoaded] = React.useState(false)
  useEffect(() => {
    const segmentLoaded = () => setSegmentIsLoaded(true)
    document.addEventListener(SEGMENT_LOADED_EVENT_ID, segmentLoaded)
    return () => {
      document.removeEventListener(SEGMENT_LOADED_EVENT_ID, segmentLoaded)
    }
  }, [])
  return isBrowser() && (segmentLoadedEventFired || "analytics" in window)
}
