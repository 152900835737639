import { useCallback, useEffect, useMemo, useState } from "react"

import { isValueOption, Option, ValueOption } from "./types"

type Args = {
  options: readonly Option[]
  onEnterPress: (option: ValueOption) => void
  boxIsOpen?: boolean
  setBoxIsOpen?: (newValue: boolean) => void
  value?: string
  ref: React.RefObject<HTMLDivElement>
  inputRef: React.RefObject<HTMLInputElement>
}

const useKeyboardNavigation = ({
  options,
  onEnterPress,
  boxIsOpen,
  setBoxIsOpen,
  value,
  ref,
  inputRef,
}: Args) => {
  const nonDividerOptions = useMemo(
    () => options.filter(isValueOption),
    [options],
  )
  const getInitialIndex = useCallback(
    () =>
      value ? nonDividerOptions.findIndex(option => option.value === value) : 0,
    [nonDividerOptions, value],
  )
  const [focusedItemIndex, setFocusedItemIndex] = useState(getInitialIndex())

  const getValidIndex = useCallback(
    (index: number) =>
      Math.min(Math.max(index, 0), nonDividerOptions.length - 1),
    [nonDividerOptions],
  )

  const offsetFocusedItem = useCallback(
    (offset: -1 | 0 | 1) => {
      setFocusedItemIndex(prevIndex => getValidIndex(prevIndex + offset))
    },
    [getValidIndex],
  )

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (
        ref.current !== document.activeElement &&
        !ref.current?.contains(document.activeElement)
      ) {
        return
      }
      if (!boxIsOpen) {
        if (e.key === "Enter") {
          e.preventDefault()
          setBoxIsOpen?.(true)
        }
        return
      }

      const { key } = e
      const keyActionMap: Record<string, () => void> = {
        ArrowUp: () => offsetFocusedItem(-1),
        ArrowDown: () => offsetFocusedItem(1),
        Tab: () => offsetFocusedItem(e.shiftKey ? -1 : 1),
        Enter: () => {
          const option = nonDividerOptions[focusedItemIndex]
          if (option) {
            onEnterPress(option)
          }
        },
        Escape: () => {
          inputRef.current?.focus()
          setBoxIsOpen?.(false)
        },
      }
      const action = keyActionMap[key]
      if (action) {
        e.preventDefault()
        action()
      }
    },
    [
      boxIsOpen,
      setBoxIsOpen,
      focusedItemIndex,
      onEnterPress,
      nonDividerOptions,
      ref,
      inputRef,
      offsetFocusedItem,
    ],
  )

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown)
    return () => {
      document.removeEventListener("keydown", handleKeyDown)
    }
  }, [handleKeyDown])

  useEffect(() => {
    setFocusedItemIndex(getInitialIndex())
  }, [boxIsOpen, getInitialIndex])

  return {
    focusedItemIndex,
  }
}

export default useKeyboardNavigation
