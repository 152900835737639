import { useEffect, useRef, useState } from "react"

import { isBrowser } from "../../browser"

type Args<T> = {
  htmlId?: string
  setInputValue?: (value: T) => void
}

/**
 * This is needed to prevent Gatsby from overriting the input value when rehydrating
 * If an input uses this, it will grab the value of the static HTML input and store it to React during the hydration process
 * @param htmlId The id of the input element, needed to grab the value from the static HTML
 * @param setInputValue The function to set the value of the input in React
 * If either of these are not provided, the hook will not do anything
 */
export function useRetainHtmlInputValue<T>({ htmlId, setInputValue }: Args<T>) {
  const [hasHydrated, setHasHydrated] = useState(false)
  const inputValueRef = useRef<T | undefined>(undefined)

  const isRehydrating = isBrowser() && !hasHydrated
  const canForceUpdate = htmlId && !!setInputValue

  if (isRehydrating && canForceUpdate) {
    const input = document.querySelector<HTMLInputElement>(`#${htmlId}`)
    if (input) {
      inputValueRef.current = input.value as T
    }
  }

  useEffect(() => {
    if (isRehydrating) {
      setHasHydrated(true)
      if (setInputValue && inputValueRef.current) {
        setInputValue(inputValueRef.current)
      }
    }
  }, [isRehydrating, canForceUpdate, htmlId, setInputValue])
}
