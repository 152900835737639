/* eslint-disable no-restricted-syntax */

import type { AnalyticsSnippet } from "@segment/analytics-next"

export interface AnalyticsWindow extends Window {
  analytics: AnalyticsSnippet
}

/**
 * Type check function to verify a value is defined. Useful for using as a callback to filter an array
 * @example arrayWithSomeUndefinedValues.filter(isDefined) // Results in an array with only the defined values.
 * @param {*} value The value to check
 * @returns {boolean} Returns true if value is defined, and false if not if it's undefined
 */
export const isDefined = <T>(value: T): value is Exclude<T, undefined> =>
  typeof value !== "undefined"

/**
 * Type check function to verify a value is truthy. Useful for using as a callback to filter an array
 * Excluded values include: `false, null, undefined, "", 0, and void`
 * @example arrayWithSomeNonTruthyValues.filter(isTruthy) // Results in an array with only the truthy values.
 * @param value The value to check
 * @returns {boolean} Returns true if value is defined, and false if not if it's undefined
 */
export const isTruthy = <T>(
  arg: T,
): arg is Exclude<T, false | null | undefined | "" | 0 | void> => !!arg

/**
 * Function to check if a value is an empty object or array. Will return false if object has keys, even if their value is undefined.
 * @param arg The value to check
 */
export function isEmpty(arg: Array<unknown>): arg is Array<never>
export function isEmpty(
  arg: Record<string | number | symbol, unknown>,
): arg is Record<string | number | symbol, never>
export function isEmpty(
  arg: Record<string | number | symbol, unknown> | Array<unknown>,
) {
  return Object.keys(arg).length === 0
}

/**
 * Checks if `value` is the
 * of `Object`. (e.g. arrays, functions, objects, regexes, `new Number(0)`, and `new String('')`)
 *
 * @category Lang
 * @param {*} value The value to check.
 * @returns {boolean} Returns `true` if `value` is an object, else `false`.
 * @example
 *
 * isObject({})
 * // => true
 *
 * isObject([1, 2, 3])
 * // => true
 *
 * isObject(Function)
 * // => true
 *
 * isObject(null)
 * // => false
 */
export function isObject(value: unknown): value is object {
  const type = typeof value
  return value != null && (type === "object" || type === "function")
}

/**
 * Check if a value is a boolean
 * @param value The value to check
 * @returns {boolean} Returns true if value is a boolean, and false if not
 */

export const isBoolean = (value: unknown): value is boolean =>
  typeof value === "boolean"

/**
 * Check if a value is a string
 * @param {*} value The value to check
 * @returns {boolean} Returns true if value is a string, and false if not
 */
export const isString = (value: unknown): value is string =>
  typeof value === "string"
