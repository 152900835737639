import { minimatch } from "minimatch"
import Cookies from "universal-cookie"

import { uniqBy } from "../utils/array"
import {
  DEFAULT_BUCKET,
  EXPERIMENT_ID_VARIANT_SEPARATOR,
  EXPERIMENT_PAIR_SEPARATOR,
  EXPERIMENT_PATH_PREFIX,
  getExperimentBucketCookieKey,
} from "./constants"
import {
  experimentMatcherData,
  uniqueExperiments,
} from "./statsig-experiment-data"
import {
  BucketUncheckedCombination,
  BucketValidCombination,
  CalculatedExperimentsWithParameters,
  Experiment,
  ExperimentGatsbyPageContext,
  ExperimentMatchers,
  ExperimentsMatchedByPathname,
} from "./types"
import { isPathname, Pathname } from "./types/base-paths"

export const STATSIG_TIER = process.env.GATSBY_ENV

type ExperimentMatcherEntry = [ExperimentMatchers, readonly Experiment[]]
// Simple matcher check, allows for "*" as wildcards
export const matcherMatchesPath = (
  matcher: string,
  pathname: Pathname,
): boolean => minimatch(pathname, matcher)

export const isExperimentPath = (path?: Pathname) =>
  !!path?.startsWith(EXPERIMENT_PATH_PREFIX)

export const getFullExperimentPath = (
  experimentsBucketsId: Pathname,
  basePath: Pathname,
): Pathname => `${EXPERIMENT_PATH_PREFIX}${experimentsBucketsId}${basePath}`

export function getExperimentsByPathname<T extends Pathname>(
  pathname: T,
): ExperimentsMatchedByPathname<T>[] {
  const entries = Object.entries(
    experimentMatcherData,
  ) as Array<ExperimentMatcherEntry>
  const matchedEntries = entries.filter(([pathMatcher]) =>
    matcherMatchesPath(pathMatcher, pathname),
  ) as Array<[ExperimentMatchers, ExperimentsMatchedByPathname<T>[]]>

  return uniqBy(
    matchedEntries
      .map(([, experiments]) => experiments)
      .flat()
      .sort((a, b) => a.id.localeCompare(b.id)),
    ({ id }) => id,
  )
}

export const getExperimentIdsByPathname = <T extends Pathname = Pathname>(
  pathname: T,
) => {
  const experiments = getExperimentsByPathname(pathname)
  return experiments.map(
    ({ id }) => id as ExperimentsMatchedByPathname<T>["id"],
  )
}

export function getIsValidBucket<T extends Pathname>(
  experimentId: string,
  variantId: unknown,
  pathname: T,
): variantId is ExperimentsMatchedByPathname<T>["variants"][number]["id"] {
  const experiments = pathname
    ? getExperimentsByPathname(pathname)
    : uniqueExperiments
  const experiment = experiments.find(({ id }) => id === experimentId)
  return experiment?.variants.some(({ id }) => id === variantId) ?? false
}

const getExperimentBucketStringPair = <T extends Pathname>(
  pathname: T,
  experimentId: string,
  variantId?: string,
) => {
  const isValidBucket = getIsValidBucket(experimentId, variantId, pathname)
  const validVariantId = isValidBucket ? variantId : DEFAULT_BUCKET
  return `${experimentId}${EXPERIMENT_ID_VARIANT_SEPARATOR}${validVariantId}`
}

export const getIsValidCombination = <T extends Pathname>(
  pathname: T,
  uncheckedCombination: Record<string, unknown>,
): uncheckedCombination is BucketValidCombination<T> => {
  const experimentIds = getExperimentIdsByPathname(pathname)
  const everyPossibleIdExistsInCombination = experimentIds.every(
    experimentId => {
      const bucket = uncheckedCombination[experimentId]
      return getIsValidBucket(experimentId, bucket, pathname)
    },
  )
  const everyKeyIsValid = Object.keys(uncheckedCombination).every(key =>
    experimentIds.includes(key as keyof BucketValidCombination<T>),
  )
  return everyPossibleIdExistsInCombination && everyKeyIsValid
}

export const getPathPartFromCombination = <T extends Pathname>(
  basePath: T,
  dict: BucketValidCombination<T>,
): Pathname => {
  const entries = Object.entries(dict) as Array<[string, string]>
  const sortedEntries = entries.sort(([a], [b]) => a.localeCompare(b))
  return `/${sortedEntries
    .map(([experimentId, variantId]) => {
      return getExperimentBucketStringPair(basePath, experimentId, variantId)
    })
    .join(EXPERIMENT_PAIR_SEPARATOR)}`
}

export const expandBucketCombinations = <
  ExistingCombinationType extends Record<string, string | undefined>,
  NewKey extends string,
>(
  existingCombinations: ExistingCombinationType[],
  newKey: NewKey,
  newValues: string[],
) => {
  type ReturnType = ExistingCombinationType & Record<NewKey, string>

  if (!existingCombinations.length) {
    return newValues.map(value => ({ [newKey]: value }) as ReturnType)
  }

  const newCombinations = existingCombinations.flatMap(combination =>
    newValues.map(value => ({
      ...combination,
      [newKey]: value,
    })),
  )

  return newCombinations as ReturnType[]
}

export const getPossibleCombinationsByPath = <T extends Pathname>(
  pathname: T,
): BucketValidCombination<T>[] => {
  const experiments = getExperimentsByPathname(pathname)
  let existingCombinations: BucketValidCombination<T>[] = []
  experiments.forEach(experiment => {
    existingCombinations = expandBucketCombinations(
      existingCombinations,
      experiment.id,
      experiment.variants.map(({ id }) => id),
    )
  })
  return existingCombinations
}

export const getCombinationFromCookies = <T extends Pathname>(
  pathname: T,
): BucketValidCombination<T> => {
  const cookies = new Cookies()
  const experiments = getExperimentsByPathname<T>(pathname)
  const experimentIds = experiments.map(
    ({ id }) => id as ExperimentsMatchedByPathname<T>["id"],
  )
  return experimentIds.reduce<BucketValidCombination<T>>(
    (acc, experimentId) => {
      const cookieValue = cookies.get(
        getExperimentBucketCookieKey(experimentId as string),
      )
      if (cookieValue) {
        acc[experimentId] = cookieValue || undefined
      }
      return acc
    },
    {} as BucketValidCombination<T>,
  )
}

export function getExperimentParametersByCombinationAndPath<T extends Pathname>(
  combination: BucketValidCombination<T>,
) {
  const combinationKeys = Object.keys(
    combination,
  ) as (keyof BucketValidCombination<T>)[]
  const context = combinationKeys.reduce((acc, experimentId) => {
    const variantId = combination[experimentId]
    const experiment = uniqueExperiments.find(({ id }) => id === experimentId)
    const variants = experiment?.variants ?? []
    const experimentVariantData =
      variants.find(({ id }) => id === variantId) ??
      variants.find(({ id }) => id === DEFAULT_BUCKET)
    // @ts-expect-error - Couldn't figure out a way to make the assignment allowed by TS, but the runtime code works as intended
    acc[experimentId] = experimentVariantData
    return acc
  }, {} as CalculatedExperimentsWithParameters<T>)
  return context
}

export const getFallbackCombination = <T extends Pathname>(
  basePath: T,
  combination: BucketUncheckedCombination<T> = {},
): BucketValidCombination<T> => {
  const experimentIds = getExperimentIdsByPathname(basePath)
  return experimentIds.reduce<BucketValidCombination<T>>(
    (acc, experimentId) => {
      const bucketOrError = combination[experimentId]
      const bucket = getIsValidBucket(experimentId, bucketOrError, basePath)
        ? bucketOrError
        : DEFAULT_BUCKET
      // @ts-expect-error - The bucket is guaranteed to be valid, but TS can't infer it
      acc[experimentId] = bucket
      return acc
    },
    {} as BucketValidCombination<T>,
  )
}

const FALLBACK_COMBINATION_ALL_EXPERIMENTS = Object.entries(
  experimentMatcherData,
).reduce<BucketValidCombination<"/**">>((acc, [, experiments]) => {
  experiments.forEach(experiment => {
    acc[experiment.id] = DEFAULT_BUCKET
  })
  return acc
}, {} as BucketValidCombination<"/**">)

export const getFallbackParametersAllExperiments = <T extends Pathname>() =>
  getExperimentParametersByCombinationAndPath<T>(
    FALLBACK_COMBINATION_ALL_EXPERIMENTS,
  )

export const isExperimentGatsbyPageContext = <T extends Pathname>(
  gatsbyPageContext: ExperimentGatsbyPageContext<T> | Record<string, unknown>,
): gatsbyPageContext is ExperimentGatsbyPageContext<T> =>
  "basePath" in gatsbyPageContext &&
  "isDynamic" in gatsbyPageContext &&
  isPathname(gatsbyPageContext.basePath) &&
  getExperimentsByPathname(gatsbyPageContext.basePath).length > 0

export const getCombinationFromGatsbyPageContext = <T extends Pathname>({
  basePath,
  isDynamic,
  combination,
}: ExperimentGatsbyPageContext<T>): BucketValidCombination<T> =>
  isDynamic ? getCombinationFromCookies(basePath) : combination

export const getFullExperimentPathFromCombination = <T extends Pathname>(
  basePath: T,
  combination: BucketValidCombination<T>,
) => {
  const combinationPathPart = getPathPartFromCombination<T>(
    basePath,
    combination,
  )
  return getFullExperimentPath(combinationPathPart, basePath)
}

export function getCleanPathname(pathname: string): Pathname {
  const withoutTrailingSlash = pathname.replace(/\/$/, "") || "/"
  const withLeadingSlash: Pathname = withoutTrailingSlash.startsWith("/")
    ? (withoutTrailingSlash as Pathname)
    : `/${withoutTrailingSlash}`
  return withLeadingSlash
}
