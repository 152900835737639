import * as React from "react"

import { useRetainHtmlInputValue } from "../../utils/hooks/use-retain-html-input-value"
import * as styles from "./index.module.scss"

export type Props = {
  variant?: "large" | "xmedium" | "medium"
  invalid?: boolean
  prefix?: () => JSX.Element
  "data-testid"?: string
  className?: string
} & (
  | {
      setInputValue: (value: string) => void
      id: string
    }
  | { setInputValue?: never }
) &
  Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    "size" | "prefix" | "suffix"
  >

const Input = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    variant = "large",
    className,
    invalid = false,
    setInputValue,
    prefix,
    ...otherProps
  } = props

  useRetainHtmlInputValue({ htmlId: otherProps.id, setInputValue })

  return (
    <div className={styles.inputWrapper}>
      {prefix && <div className={styles.prefix}>{prefix()}</div>}
      <input
        ref={ref}
        className={`${styles.input} ${styles[variant]} ${
          invalid && styles.invalid
        } ${prefix && styles.inputPrefixed} ${className}`}
        {...otherProps}
      />
    </div>
  )
})

Input.displayName = "Input"

export default Input
