import { TREMENDOUS_MARKETING_URL } from "../urls"

export const handleAnchorLink = (e: React.MouseEvent) => {
  e.preventDefault()
  const match = document.getElementById(
    (e.target as HTMLAnchorElement).hash.replace("#", ""),
  )
  if (match) {
    match.scrollIntoView({
      block: "start",
      behavior: "smooth",
    })
  }
}

export const isInternalUrl = (url: string) => {
  return url.startsWith("/") || url.startsWith(TREMENDOUS_MARKETING_URL)
}
