import React, { ComponentType, CSSProperties } from "react"

import * as styles from "./index.module.scss"
import SelectionBoxItem from "./selection-box-item"
import { isDivider, isValueOption, Option, ValueOption } from "./types"
import { getOptionsWithMergedDividers } from "./utils"

const BASE_MARGIN = ".5rem"

interface Props {
  options: readonly Option[]
  onClick: (option: ValueOption) => void
  renderOption?: ComponentType<ValueOption>
  selectedItem?: ValueOption | null
  style?: CSSProperties
  focusedItemIndex: number
  inputRef?: React.RefObject<HTMLInputElement>
  className?: string
}

function SelectionBox({
  options,
  onClick,
  renderOption,
  selectedItem,
  focusedItemIndex,
  style,
  inputRef,
  className,
}: Props) {
  const divider = (key: number) => (
    <div
      className={styles.divider}
      style={{ pointerEvents: "none" }}
      key={`divider-${key}`}
    />
  )

  const inputWidth = inputRef?.current?.getBoundingClientRect().width
  const nonDividerOptions = options.filter(isValueOption)
  const optionsWithMergedDivider = getOptionsWithMergedDividers(options)
  if (!optionsWithMergedDivider.length) {
    return null
  }

  return (
    <div
      className={`${styles.container} ${className}`}
      role="listbox"
      tabIndex={-1}
      style={{ minWidth: inputWidth, ...style }}
      onMouseDown={e => e.preventDefault()}
    >
      {optionsWithMergedDivider.map((option, index, array) =>
        isDivider(option) ? (
          divider(index)
        ) : (
          <div
            style={{
              marginLeft: BASE_MARGIN,
              marginRight: BASE_MARGIN,
              marginTop: !index ? "0" : BASE_MARGIN,
              marginBottom: index === array.length - 1 ? "0" : BASE_MARGIN,
            }}
            key={option.value}
          >
            <SelectionBoxItem
              option={option}
              onClick={onClick}
              optionComponent={renderOption}
              selectedItem={selectedItem}
              isFocused={
                option.value === nonDividerOptions[focusedItemIndex]?.value
              }
            />
          </div>
        ),
      )}
    </div>
  )
}

export default SelectionBox
