import "./src/styles/global.scss"
import "./src/styles/font-faces.scss"

import BugsnagPerformance from "@bugsnag/browser-performance"
import Bugsnag from "@bugsnag/js"
import BugsnagPluginReact from "@bugsnag/plugin-react"
import { RouteUpdateArgs } from "gatsby"
import React from "react"
import Cookies from "universal-cookie"

import { MIDDLEWARE_EXPERIMENT_IDS_COOKIE } from "./src/experimentation/constants"
import { Pathname } from "./src/experimentation/types/base-paths"
import { isExperimentPath } from "./src/experimentation/utils"

declare global {
  interface Window {
    pagePath?: string
  }
}

const apiKey = process.env.GATSBY_BUGSNAG_API_KEY as string
Bugsnag.start({
  apiKey,
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.GATSBY_ENV,
  appVersion: process.env.CIRCLE_SHA1,
})
BugsnagPerformance.start({ apiKey })

const ErrorBoundary =
  Bugsnag.getPlugin("react")?.createErrorBoundary(React) ?? React.Fragment

export const wrapRootElement = ({ element }) => (
  <ErrorBoundary>{element}</ErrorBoundary>
)

export { wrapPageElement } from "./gatsby-shared"

export const onClientEntry = () => {
  if (isExperimentPath(window.pagePath as Pathname)) {
    // Remove the experiment path prefix from the page path so that Gatsby doesn't internally redirect to the canonical URL.
    window.pagePath = window.pagePath?.replace(/\/experiment\/[^/]+/g, "")
  }
}

export const onPreRouteUpdate = ({ prevLocation }: RouteUpdateArgs) => {
  if (!prevLocation) {
    return
  }
  const cookies = new Cookies()
  /** The cookie is only needed within the page that was just visited through an external link. Afterwards, statsig client-side SDK should take over.
   * See more details on the JSDoc of the cookie key constant.
   */
  cookies.remove(MIDDLEWARE_EXPERIMENT_IDS_COOKIE, {
    path: "/",
  })
}
