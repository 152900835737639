import React, { ComponentType, useLayoutEffect, useRef } from "react"

import Paragraph from "../../../paragraph"
import { ValueOption } from "../types"
import * as styles from "./index.module.scss"

interface Props {
  option: ValueOption
  onClick: (option: ValueOption) => void
  optionComponent?: ComponentType<ValueOption>
  selectedItem?: ValueOption | null
  isFocused: boolean
}

function SelectionBoxItem({
  onClick,
  option,
  optionComponent,
  selectedItem,
  isFocused,
}: Props) {
  const isSelected = selectedItem?.value === option?.value
  const ref = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    if (isFocused) {
      ref.current?.scrollIntoView?.({ block: "nearest", inline: "nearest" })
    }
  }, [isFocused])

  const OptionComponent = optionComponent
  return (
    <div
      className={`${styles.container} ${isSelected && styles.selected} ${
        isFocused && styles.focused
      } ${!optionComponent && styles.noCustomRender}`}
      onMouseDown={e => {
        e.preventDefault()
        onClick(option)
      }}
      ref={ref}
      role="option"
      tabIndex={-1}
      aria-current={isFocused}
      aria-selected={selectedItem?.value === option?.value}
    >
      {OptionComponent ? (
        <OptionComponent {...option} />
      ) : (
        <Paragraph variant="3">{option.label}</Paragraph>
      )}
    </div>
  )
}

export default SelectionBoxItem
