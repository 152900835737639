import React from "react"

import * as styles from "./index.module.scss"

export interface Props extends React.HTMLAttributes<HTMLParagraphElement> {
  variant: "1" | "2" | "3"
  as?: "p" | "span"
  textColor?: "white" | "none"
}

function Paragraph({
  as = "p",
  variant,
  className,
  textColor = "none",
  ...rest
}: Props) {
  const Element = as
  const variantClassName = styles[`variant${variant}`]

  return (
    <Element
      className={`${styles.paragraph} ${variantClassName} ${
        textColor === "white" ? styles.paragraphWhite : ""
      } ${className}`}
      {...rest}
    />
  )
}

export default Paragraph
