export const TREMENDOUS_DOMAIN = "www.tremendous.com"
export const TREMENDOUS_MARKETING_URL = `https://${TREMENDOUS_DOMAIN}`
export const TREMENDOUS_APP_SIGNUP = "https://app.tremendous.com/auth/signup?"
export const TREMENDOUS_APP_LOGIN = "https://app.tremendous.com/auth/login"

export const TREMENDOUS_PRESS_EMAIL = "press@tremendous.com"
export const TREMENDOUS_TWITTER_URL = "https://twitter.com/gotremendous"
export const TREMENDOUS_LINKEDIN_URL =
  "https://www.linkedin.com/company/tremendous-rewards/"
export const TREMENDOUS_PEOPLE_LINKEDIN_URL = `${TREMENDOUS_LINKEDIN_URL}people/`
export const ASHBY_EXTERNAL_URL = "https://jobs.ashbyhq.com/Tremendous"
export const ASHBY_EMBED_URL = `${ASHBY_EXTERNAL_URL}/embed`
const TREMENDOUS_DEVELOPER_DOCS_BASE = "https://developers.tremendous.com"

export const getDeveloperDocsUrl = (path: string) =>
  TREMENDOUS_DEVELOPER_DOCS_BASE + path
export const TREMENDOUS_DEVELOPER_DOCS = getDeveloperDocsUrl(
  "/reference/introduction",
)
export const getCoreUrl = (path: string) => process.env.GATSBY_CORE_URL + path
export const CATALOG_DATA_URL = getCoreUrl("/catalog-file.json?v3=true")
export const SEND_DEMO_URL = getCoreUrl("/send-demo")

export const TREMENDOUS_RECIPIENT_GENERAL_TOUR_URL = `${process.env.GATSBY_RECIPIENT_URL}/demo`
export const getRecipientGeneralTourUrl = (path: string) =>
  TREMENDOUS_RECIPIENT_GENERAL_TOUR_URL + path

export const PRESS_KIT_DOWNLOAD_URL =
  "https://drive.google.com/file/d/1a-TktvlnLYxUfkM7uQk3uVZKxlTujQFL/view"
export const TREMENDOUS_STATUS_URL = "https://status.tremendous.com/"
export const TREMENDOUS_HELP_URL = "https://help.tremendous.com/"
export const CLIENT_TOUR_URL =
  "https://app.teamwalnut.com/player/?demoId=e45b212e-1a20-413b-906d-b1e4d2a20d7f&screenId=e03f5503-ad7d-4886-a4b6-f83fc0dff5e7&showGuide=true&showGuidesToolbar=true&showHotspots=true&source=app"

export const BOOST_SALES_REBATES_WHITEPAPER_URL =
  "https://get.tremendous.com/hubfs/Boost%20B2C%20sales%2050%20percent%20with%20better%20digital%20rebates.pdf"
export const DATO_CMS_URL =
  "https://tremendous-marketing-website.admin.datocms.com"
