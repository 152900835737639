import { TREMENDOUS_MARKETING_URL } from "../../../utils/urls"

export const getUrlWithTrailingSlash = (url: string) => {
  const hasDomain = url.startsWith("http")
  const isMailTo = url.startsWith("mailto:")
  const isExternalUrl = hasDomain && !url.startsWith(TREMENDOUS_MARKETING_URL)
  const isWithoutSlash = !hasDomain && !url.startsWith("/") // The routing has a different behavior for URLs without a leading slash, they are treated as relative to the current path
  if (isExternalUrl || isMailTo) {
    // For external URLs we can't be sure that they can handle trailing slashes correctly, so we leave them unchanged
    return url
  }
  const rest = url.replace(TREMENDOUS_MARKETING_URL, "")
  const restWithLeadingSlash = rest.startsWith("/") ? rest : `/${rest}`
  const parsedUrl = new URL(
    `${TREMENDOUS_MARKETING_URL}${restWithLeadingSlash}`,
  )
  parsedUrl.pathname = `${parsedUrl.pathname}/`.replace("//", "/")
  const resultWithoutDomain = parsedUrl.href.replace(
    TREMENDOUS_MARKETING_URL,
    "",
  )
  return resultWithoutDomain.slice(isWithoutSlash ? 1 : 0)
}

export const getCanonicalUrl = (pathname: string) => {
  // throw an error if pathname has the `/experiment` path more than once:
  if ((pathname.match(/\/experiment\//g) || []).length > 1) {
    throw new Error(
      `getCanonicalUrl was called with an invalid pathname: ${pathname}`,
    )
  }

  // throw an error if pathname is malformed
  if (!pathname.startsWith("/") || pathname.includes("?")) {
    throw new Error(
      `getCanonicalUrl was called with an invalid pathname: ${pathname}`,
    )
  }

  const pathnameWithTrailingSlash = getUrlWithTrailingSlash(pathname)

  return (
    TREMENDOUS_MARKETING_URL +
    pathnameWithTrailingSlash.replace(/^\/experiment\/[^/]+\//g, "/")
  ).toLowerCase()
}
