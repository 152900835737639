// eslint-disable-next-line no-restricted-imports
import { Link } from "gatsby"
import React, { ReactNode } from "react"

import { getUrlWithTrailingSlash } from "../../shared-components/seo/canonical-tag/utils"
import * as analytics from "../../utils/analytics"
import { EventProperties } from "../../utils/analytics"
import { classNames } from "../../utils/class-names"
import * as styles from "./index.module.scss"

interface Props {
  to: string
  className?: string
  target?: "_blank"
  title?: string
  mediumWeight?: boolean
  children?: ReactNode
  trackingProperties?: EventProperties
  tabIndex?: number
  onClick?: () => void
  onMouseUp?: () => void
}

function UniversalLink({
  to,
  className,
  target,
  title,
  mediumWeight,
  children,
  trackingProperties,
  tabIndex,
  onClick,
  onMouseUp,
  ...rest
}: Props) {
  const hrefWithTrailingSlash = getUrlWithTrailingSlash(to)
  const isExternal = hrefWithTrailingSlash.startsWith("http")
  const clickLinkHandler = () => {
    if (trackingProperties) {
      analytics.itemClicked(trackingProperties)
    }
    onClick?.()
  }
  const commonProps = {
    className: classNames(className, mediumWeight && styles.mediumWeight),
    onClick: clickLinkHandler,
    onKeyDown: clickLinkHandler,
    onMouseUp,
    tabIndex,
  }

  if (isExternal) {
    return (
      <a
        href={hrefWithTrailingSlash}
        rel="noopener noreferrer"
        title={title}
        target={target}
        {...commonProps}
        {...rest}
      >
        {children}
      </a>
    )
  }

  return (
    <Link to={hrefWithTrailingSlash} {...commonProps} {...rest}>
      {children}
    </Link>
  )
}

export default UniversalLink
