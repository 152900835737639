// extracted by mini-css-extract-plugin
export var border1 = "cY_hZ";
export var border2 = "cY_h0";
export var border3 = "cY_h1";
export var border4 = "cY_h2";
export var container = "cY_h";
export var dark = "cY_dk";
export var large = "cY_b5";
export var ldsRing = "cY_h3";
export var light = "cY_dj";
export var medium = "cY_b6";
export var primaryPressed = "cY_h4";
export var xlarge = "cY_hY";