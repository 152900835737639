import React, { useEffect, useState } from "react"

import ChevronDropdown from "../../shared-components/chevron-dropdown"
import Accordion from "../accordion"
import Paper from "../paper"
import * as styles from "./index.module.scss"

interface Props {
  trigger?: React.ReactNode
  children?: React.ReactNode
  className?: string
  id?: string
  chevronSmall?: boolean
  paddingSmall?: boolean
  externalIsExpanded?: boolean
}

function PaperExpandable({
  trigger,
  id,
  children,
  className,
  chevronSmall = false,
  paddingSmall = false,
  externalIsExpanded = false,
}: Props) {
  const [isCollapsed, setIsCollapsed] = useState(!externalIsExpanded)
  useEffect(() => {
    setIsCollapsed(!externalIsExpanded)
  }, [externalIsExpanded])
  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed)
  }

  return (
    <Paper className={`${styles.paperExpandable} ${className}`} id={id}>
      <Accordion
        className={`${styles.buttonWrapper} ${
          paddingSmall ? styles.paddingSmall : ""
        }`}
        isCollapsed={isCollapsed}
        toggleCollapse={handleToggleCollapse}
        trigger={
          <div className={styles.paperTriggerWrapper}>
            {trigger}
            <div
              className={`${styles.paperTrigger} 
              ${chevronSmall ? styles.paperTriggerSmall : ""}`}
            >
              <ChevronDropdown
                isOpen={!isCollapsed}
                size={chevronSmall ? "small" : "medium"}
              />
            </div>
          </div>
        }
        content={
          // Wrappers children inside a flex div to help
          // According include children's margins when
          // calculating height.
          <div className={styles.paperContent}>{children}</div>
        }
      />
    </Paper>
  )
}

export default PaperExpandable
