import React, { useMemo } from "react"

import { Pathname } from "../../experimentation/types/base-paths"
import { isBrowser } from "../browser"
import { LeadRoutes } from "../url-params"

export interface LocationState {
  hubspotOwnerId?: string
  spend?: string
  email?: string
  leadLevel?: LeadRoutes
}
type PageContextType = {
  pathname: Pathname
  search: string
  state: LocationState
}

interface Props {
  children: React.ReactNode
  pathname: Pathname
  search: string
  state: LocationState
}

export const PageContext = React.createContext<PageContextType>({
  pathname: "" as Pathname,
  search: "",
  state: {},
})

export const PageProvider = ({ children, pathname, search, state }: Props) => {
  const value = useMemo(
    () => ({
      pathname,
      search,
      state,
    }),
    [pathname, search, state],
  )

  return <PageContext.Provider value={value}>{children}</PageContext.Provider>
}

export const usePageContext = () => {
  const context = React.useContext(PageContext)
  if (context === undefined) {
    throw new Error("usePageContext must be used within a PageProvider")
  }
  return context
}

export const useQueryParams = () => {
  const { search } = usePageContext()
  return useMemo(() => {
    if (!isBrowser()) return {}
    const urlParams = new URLSearchParams(search)
    return Object.fromEntries(urlParams)
  }, [search])
}

export const useLocationState = (): LocationState => {
  const { state } = usePageContext()
  return useMemo(() => {
    if (!isBrowser()) return {}
    if (!state) return {}
    return state
  }, [state])
}
