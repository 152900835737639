import * as React from "react"
import { useEffect, useMemo, useState } from "react"
import Statsig from "statsig-js"
import Cookies from "universal-cookie"

import ExperimentStatisticsPopup from "../../shared-components/mock-experiment/experiment-statistics-popup"
import { useSegmentIsLoaded } from "../../shared-components/seo/use-segment-is-loaded"
import { eventNames, track } from "../../utils/analytics"
import { isBrowser } from "../../utils/browser"
import { getCookiesAndExperimentUrl } from "../bucketing/statsig-router"
import { EXPERIMENT_UID_COOKIE, MOCK_EXPERIMENT_PATH } from "../constants"
import { getStatsigUser } from "../middleware/statsig-user-custom-data"
import { BucketValidCombination, ExperimentGatsbyPageContext } from "../types"
import { Pathname } from "../types/base-paths"
import {
  getCleanPathname,
  getCombinationFromGatsbyPageContext,
  getIsValidBucket,
  getIsValidCombination,
  isExperimentPath,
} from "../utils"
import { ExperimentContext } from "./experiment-context"
import { useStatsigInitialized } from "./statsig-provider"

const RESERVE_BUCKET = "reserve"
const RESERVE_STATSIG_STATUS = "layer assignment"

function clientSideSetExperimentCookies(
  isDynamic: boolean,
  pathname: Pathname,
) {
  const cookies = new Cookies()
  if (isDynamic) {
    const statsigUserData = getStatsigUser(name => cookies.get(name))
    const result = getCookiesAndExperimentUrl(
      pathname,
      (_, name: string) => Statsig.getExperiment(name),
      statsigUserData,
    )
    if (result) {
      // When navigating through internal links, the middleware is not invoked, so we need to set the cookies client side.
      const { cookieList } = result
      cookieList.forEach(cookieToSet => {
        document.cookie = cookieToSet
      })
    }
  }
}

type Props = {
  children: React.ReactNode
  gatsbyPageContext: ExperimentGatsbyPageContext<Pathname>
  pathname: Pathname
}

const ExperimentProvider = ({
  children,
  gatsbyPageContext,
  pathname,
}: Props) => {
  const { isInitialized: statsigIsInitialized } = useStatsigInitialized()

  if (statsigIsInitialized && gatsbyPageContext?.isDynamic) {
    // Since Statsig on client side loads asynchronously, upon the first visit of a page,
    // this will be based on the cookie, before it gets overriden by the client side fetched bucket.
    clientSideSetExperimentCookies(gatsbyPageContext?.isDynamic, pathname)
  }

  const cookies = new Cookies()
  const userId = cookies.get<string | undefined>(EXPERIMENT_UID_COOKIE)

  const experimentCombination = useMemo(
    () => getCombinationFromGatsbyPageContext(gatsbyPageContext),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [gatsbyPageContext, userId], // userId is not used in the memoization, but it's relevant for the value
  )

  const isValidCombination =
    !gatsbyPageContext || getIsValidCombination(pathname, experimentCombination)

  const segmentIsLoaded = useSegmentIsLoaded()
  useEffect(() => {
    if (
      segmentIsLoaded &&
      gatsbyPageContext?.isDynamic &&
      experimentCombination
    ) {
      const combinationEntries = Object.entries(experimentCombination) as [
        keyof BucketValidCombination<Pathname>,
        string,
      ][]
      combinationEntries.forEach(([experimentId, variantId]) => {
        const trackingBasicData = {
          experimentId,
          pageName: pathname,
          statsigUserId: userId,
        }
        const isReserve = variantId?.startsWith(RESERVE_STATSIG_STATUS)
        if (getIsValidBucket(experimentId, variantId, pathname) || isReserve) {
          track(eventNames.experimentExposure, {
            ...trackingBasicData,
            experimentBucket: isReserve
              ? RESERVE_BUCKET
              : experimentCombination[experimentId],
          })
        } else {
          track(eventNames.experimentBucketError, {
            ...trackingBasicData,
            notBucketedReason: experimentCombination[experimentId],
          })
        }
      })
    }
  }, [
    segmentIsLoaded,
    gatsbyPageContext?.isDynamic,
    experimentCombination,
    pathname,
    userId,
  ])

  const [metatag, setMetatag] = useState<React.ReactNode>(null)
  useEffect(() => {
    if (isBrowser() && isValidCombination) {
      setMetatag(
        <meta
          property="experiment-path"
          content={`/experiment/${experimentCombination}${pathname}`}
        />,
      )
    }
  }, [pathname, experimentCombination, isValidCombination])

  const providerValue = useMemo(
    () => ({
      combination: experimentCombination,
      userId,
      pathname,
      basePath: gatsbyPageContext?.basePath ?? pathname,
    }),
    [experimentCombination, userId, pathname, gatsbyPageContext?.basePath],
  )

  return (
    <ExperimentContext.Provider value={providerValue}>
      {metatag}
      {children}
      {process.env.GATSBY_ENV !== "production" &&
        !getCleanPathname(pathname).includes(MOCK_EXPERIMENT_PATH) && // Unnecessary because the full stats show up on the page
        gatsbyPageContext &&
        (gatsbyPageContext.isDynamic || isExperimentPath(pathname)) && (
          <ExperimentStatisticsPopup
            userIdFromCookie={userId}
            isDynamic={gatsbyPageContext.isDynamic}
            basePath={gatsbyPageContext.basePath}
            combination={experimentCombination}
          />
        )}
    </ExperimentContext.Provider>
  )
}

export default ExperimentProvider
