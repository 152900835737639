import { isBrowser } from "../browser"

export const getUserEmail = (): string => {
  if (!isBrowser()) return ""

  const userInfo = localStorage.getItem("userInfo")
  if (userInfo) {
    return JSON.parse(userInfo).email || ""
  }
  const params = new URLSearchParams(isBrowser() ? window.location.search : "")
  return params.get("email") || ""
}
export const setUserInfo = ({ email }: { email: string }): void => {
  if (!isBrowser()) return
  localStorage.setItem("userInfo", JSON.stringify({ email }))
}

export const checkFormSubmission = (form: string): boolean => {
  if (!isBrowser()) return false

  return !!localStorage.getItem(form)
}

export const getUserCountryFromLanguage = (): string | undefined => {
  if (!isBrowser()) return ""
  const { language } = window.navigator
  const countryCode = new Intl.Locale(language).region
  return countryCode
}
