import { isBrowser } from "../../utils/browser"
import {
  EXPERIMENT_UID_COOKIE,
  getExperimentBucketCookieKey,
} from "../constants"
import { ExperimentGetter, StatsigUserInfo } from "../middleware/types"
import { generateCookieString } from "../middleware/utils"
import { Pathname } from "../types/base-paths"
import {
  getFallbackCombination,
  getFullExperimentPathFromCombination,
} from "../utils"
import { getExperimentBuckets } from "./get-experiment-buckets"
import {
  getMiddlewareExperimentCookie,
  getNonBucketedExperiments,
} from "./utils"

export const getCookiesAndExperimentUrl = (
  pathname: Pathname,
  getExperiment: ExperimentGetter,
  userInfo: StatsigUserInfo,
) => {
  const isMiddleware = !isBrowser()

  const experiments = getNonBucketedExperiments(pathname, isMiddleware)

  const bucketCombination = getExperimentBuckets({
    experiments,
    getExperiment,
    userInfo,
  })

  if (!bucketCombination) {
    return
  }

  const combinationEntries = Object.entries(bucketCombination) as Array<
    [string, string]
  >
  const bucketCookies = combinationEntries.map(
    ([experimentId, bucketOrError]) => {
      const cookieKey = getExperimentBucketCookieKey(experimentId)
      return generateCookieString(cookieKey, bucketOrError)
    },
  )

  const combination = getFallbackCombination(pathname, bucketCombination)
  const experimentPathname = getFullExperimentPathFromCombination(
    pathname,
    combination,
  )
  const experimentUidCookie = generateCookieString(
    EXPERIMENT_UID_COOKIE,
    userInfo.userID,
  )

  const cookieList = [...bucketCookies, experimentUidCookie]

  if (isMiddleware) {
    // If on the middleware sets a cookie so that statsig running on client-side doesn't re-evaluate the experiment
    const middlewareCookie = getMiddlewareExperimentCookie(bucketCombination)
    cookieList.push(middlewareCookie)
  }

  return { cookieList, experimentPathname }
}
