import React from "react"

import ObjectEntriesDisplay from "../object-entries-display"
import * as styles from "./index.module.scss"

type Props<T extends Record<string, string>> = {
  value?: string
  colorMap: T
  defaultKey: keyof T
  sizeInPx: number
  blocksPurposeDescription: string
  "data-testid"?: string
}

function ColoredBlockForExperimentTesting<T extends Record<string, string>>({
  value,
  colorMap,
  defaultKey,
  sizeInPx,
  blocksPurposeDescription,
  "data-testid": dataTestId,
}: Props<T>) {
  return (
    <div
      className={styles.coloredBlock}
      style={{
        width: sizeInPx,
        height: sizeInPx,
        maxWidth: sizeInPx,
        background:
          colorMap[value as keyof typeof colorMap] ?? colorMap[defaultKey],
      }}
      data-testid={dataTestId}
      data-value={value}
    >
      {blocksPurposeDescription}
      <br />
      <br />
      <ObjectEntriesDisplay object={colorMap} />
    </div>
  )
}

export default ColoredBlockForExperimentTesting
