import React from "react"

import * as styles from "./index.module.scss"

export type Props = {
  color: "tan" | "darkGray" | "blue"
  position: "left" | "right" | "fullWidth"
  zIndex?: number
  className?: string
}

function BackgroundRectangle({ color, position, zIndex, className }: Props) {
  return (
    <div
      className={`${className} ${styles.background} ${styles[color]} ${styles[position]}`}
      style={{ zIndex }}
    />
  )
}

export default BackgroundRectangle
